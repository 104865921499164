import './App.scss';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'
import Home from './routes/home/Home';
import Tiktok from './routes/tiktok/Tiktok';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAYtQga9VjNFFnBVu9w22Sq2Adx4iylmLs",
  authDomain: "mediagcs-e6c50.firebaseapp.com",
  projectId: "mediagcs-e6c50",
  storageBucket: "mediagcs-e6c50.appspot.com",
  messagingSenderId: "1075073272989",
  appId: "1:1075073272989:web:3161a7610b482155c5d874",
  measurementId: "G-TQM57N11KV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  return (
    <div className="root">
      <div className="main">
        <div className="content-window">
          <Router>
            <Routes>
              <Route path='/' element={<Home/>}/>
              <Route path='/tiktok' element={<Tiktok/>}/>
            </Routes>
          </Router>
        </div>
      </div>
    </div>
  );
}

export default App;
