import React from 'react'
import './LabelContainer.scss'

const LabelContainer = (props) => {
    return (
        <div className='label-container'>
            <div className='lc-top'>
                <h3 className='platform'>{props.platform}</h3>
                <a className='handle' href={props.href} target="_blank">{props.handle}</a>
            </div>
            <div className='lc-box'>
                {props.children}
            </div>
        </div>
    )
}

export default LabelContainer;