import React from 'react'
import '../../App.scss'
import PriceCard from '../../components/PriceCard/PriceCard'

const Tiktok = (props) => {
    return (
        <div>
            <br/><br/>
            <h1 className='yellow'>Tiktok Promotions</h1>
            <p>
                {`Thank you for your interest in working with me
                through my TikTok page, @cameronstech! Before
                moving ahead, I recommend reading `}
                <span>
                    <a className='inline-link yellow' href='/policy'>{`my content policy for business inquiries`}</a>
                </span>
                {`, which outlines the standards I require in order to
                promote your business or product to my audience.`}
            </p>
            <p>
                {`The TikTok algorithm likes to expose posts to more
                users if user engagement and watch time is high. This
                means that there is a bias for shorter videos, causing
                videos that are 8-15 seconds long to perform better
                than 30-60 second videos. This research impacts pricing
                below.`}
            </p>
            <br/><br/>
            <h1>Basic Plans</h1>
            <PriceCard
                color="#FD9040"
                borderColor="#FD9040"
                price="$500"
                description="One 60-second video covering your business or product."
                title='60 Basic'
            />
            <PriceCard
                color="#41EAD4"
                borderColor="#41EAD4"
                price="$500"
                description="One 30-second video covering your business or product."
                title='30 Basic'
            />
            <PriceCard
                color="#FBFF12"
                borderColor="#FBFF12"
                price="$500"
                description="One 15-second video covering your business or product."
                title='15 Basic'
            />
            <br/>
            <h1>Pro Plans</h1>
            <PriceCard
                color="#FD9040"
                borderColor="#FD9040"
                backgroundColor="#4A2C15"
                price="$500"
                tag="Save $250!"
                description="Three 60-second video covering your business or product."
                title='60 Pro'
            />
            <PriceCard
                color="#41EAD4"
                borderColor="#41EAD4"
                backgroundColor="#0C433C"
                price="$500"
                tag="Save $275!"
                description="Three 30-second video covering your business or product."
                title='30 Pro'
            />
            <PriceCard
                color="#FBFF12"
                borderColor="#FBFF12"
                backgroundColor="#393906"
                price="$500"
                tag="Save $300!"
                description="Three 15-second video covering your business or product."
                title='15 Pro'
            />
        </div>
    )
}

export default Tiktok