import React from 'react'
import LabelContainer from '../../components/LabelContainer/LabelContainer';
import Stat from '../../components/Stat/Stat';
import pfp from '../../assets/pfp.JPEG';
import '../../App.scss'

const Home = (props) => {
    return (
        <>
            <div className='h-center'>
                <img className='pfp' src={pfp}/><br/>
                <b className="yellow major glitch" data-glitch="Hi, I'm Cameron.">Hi, I'm Cameron.</b>
                <p className="submajor">I make tech content on the internet.</p>
            </div>
            <br/><br/>
            {/* <strong className="submajor">About Me</strong>
            <p className="paragraph">
                Hi, I’m Cameron. I’m a software engineer. You may also know the job
                title to be any of the following: programmer, coder, developer, software
                developer, software development engineer, person who codes, or something
                similar. I graduated college in 2022 with a B.Sc. in Computer Science. I’m
                co-founder of a startup called Hyprlink, and currently work as a SDE at Amazon.
                I also like video games, big surprise there! I’ve tried my hand in many crafts:
                mobile development, web development, game development, artificial intelligence,
                and scalable eCommerce solutions.
            </p> */}
            <LabelContainer platform="TikTok" handle="@cameronstech" href="https://tiktok.com/@cameronstech">
                <div class="container">
                <div class="container__row">
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="80K" label="Followers" />
                    </div>
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="1.8M" label="Likes" />
                    </div>
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="11.7M" label="Views" />
                    </div>
                </div>
                <div class="container__row">
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="93K" label="Shares" />
                    </div>
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="6.8K" label="Average Likes" />
                    </div>
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="45K" label="Average Views" />
                    </div>
                </div>
                </div>
                <a className='center' href='/tiktok'>
                <div className='action-button'>
                    Promote your business on TikTok
                </div>
                </a>
            </LabelContainer>
            <br/>
            <LabelContainer platform="LinkedIn" handle="in/cameron-gould" href="https://linkedin.com/in/cameron-gould">
                <div class="container">
                <div class="container__row">
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="1.2K" label="Followers" />
                    </div>
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="4.8K" label="Impressions/Month" />
                    </div>
                    <div class="container__col-sm-12 container__col-md-4">
                    <Stat value="300+" label="Newsletter Subscribers" />
                    </div>
                </div>
                </div>
                <a className='center' href='/linkedin'>
                <div className='action-button'>
                    Promote your business on LinkedIn
                </div>
                </a>
            </LabelContainer>
        </>
    )
}

export default Home