import React from "react";
import './Stat.css'
import useWindowDimensions from "../../hooks/useWindowDimensions";

const Stat = (props) => {
    // const { height, width } = useWindowDimensions();

    return (
        <div className='stat'>
            <div className='circle-stat'>
                {props.value}
            </div>
            <div className='stat-label'>
                {props.label}
            </div>
        </div>
    )
}

export default Stat;