import React from 'react'
import './pricecard.css'


const PriceCard = (props) => {
    return (
        <div className='price-card' style={{color:props.color, borderColor: props.borderColor, backgroundColor: props.backgroundColor ? props.backgroundColor : null}}>
            <div className='row'>
                <div className='col'>
                <div><strong>{props.title}</strong></div>
                    <div className='description'>
                        {props.description}
                    </div>
                </div>
                <div className='price'>
                    {props.price}
                </div>
            </div>
            { props.tag ? (
                <div className='tag'>
                    {props.tag}
                </div>
            ) : (<div></div>)}
        </div>
    )
}

export default PriceCard